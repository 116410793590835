import {
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import theme from '../theme/theme';

export default styled(Typography)(() => ({
  fontSize: 18,
  color: theme.palette.extras.blue.main,
  marginBottom: 14,
}));
