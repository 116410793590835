import { Typography, Grid, Box } from '@mui/material';
import { StaticImage } from 'gatsby-plugin-image';
import { styled } from '@mui/material/styles';
import { css } from '@emotion/react';

import MainLayout from '../components/MainLayout';
import SmallHero from '../components/SmallHero';
import Ingress from '../components/Ingress';
import Block from '../components/Block';
import backgroundImage from '../images/hero/scott-graham-OQMZwNd3ThU-unsplash.jpg';

export function Head() {
  return <title>Veikko Mäkinen - Hinnoittelu</title>;
}

const Price = styled(Typography)(({ theme }) => ({
  color: '#fff',
  [theme.breakpoints.down('sm')]: {
    fontSize: 22,
  },
  [theme.breakpoints.up('md')]: {
    fontSize: 34,
  },
}));

const Price2 = styled(Typography)(({ theme }) => ({
  color: '#fff',
  [theme.breakpoints.down('sm')]: {
    fontSize: 18,
  },
  [theme.breakpoints.up('md')]: {
    fontSize: 30,
  },
}));

export default function Svenska() {
  return (
    <MainLayout>
      <SmallHero title="Hinnoittelu" backgroundImage={backgroundImage} overlayColor="#000" overlayOpacity={0.7} />

      <Block sx={{ backgroundColor: 'extras.purple.main', color: 'white' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Price variant="subtitle1">
            1200 € / PV
          </Price>
          <Price2 variant="subtitle1">
            5400 € / VKO
          </Price2>
          <Typography variant="caption" sx={{ color: '#888' }}>alv. 0%</Typography>
        </Box>
      </Block>

      <Block>
        <Grid container rowSpacing={1} spacing={{ sm: 2, lg: 4 }}>
          <Grid item xs={12} sm={6} p={2}>
            <Ingress>
              Kuten kaikessa toiminnassani, uskon myös hinnoittelussa avoimuuteen.
              Kartoitamme aina ensin yhdessä tarpeet ja etsimme niihin sopivan työskentelymallin.
              Sanon aina suoraan, jos en ole mielestäni oikea henkilö tehtävään.
            </Ingress>
            <Ingress>
              Etsin ennen kaikkea mielenkiintoisia, luottamukseen ja jaettuun visioon perustuvia
              toimeksiantoja.
              Kanssani voit avoimesti keskustella myös sweat equity -vaihtoehdoista.
            </Ingress>
            <Ingress gutterBottom>
              Ja tämä laskutuksen periaate pitää aina; tyhjästä ei laskuteta ja tunnit
              pyöristyvät aina asiakkaan eduksi.
            </Ingress>

          </Grid>
          <Grid item xs={12} sm={6} p={2}>
            <StaticImage
              src="../images/act/_B__0083.jpg"
              alt="Asiakastyötä kasvokkain"
              placeholder="blurred"
              layout="constrained"
              css={css`
                border: 1px solid #aaa;
              `}
            />
          </Grid>
        </Grid>
      </Block>

    </MainLayout>
  );
}
